import React from "react";

const RefrigerantSeries = ({ value }) => {

    if (!value) {
        return null;
    }


    return (<p className="uk-margin-remove uk-text-center button-list">
        <span
            className="uk-button round uk-button-primary"
            style={{ cursor: "default" }}
        >
            Refrigerant: {value}
        </span>
    </p>)
}

export default RefrigerantSeries;