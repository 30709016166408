import { Link } from "gatsby";
import React from "react";
const slugify = require("slugify")

const PhaseOutWarning = ({ categoryId, warning, replacement }) => {

    if (!warning) {
        return null;
    }

    const slug = slugify((replacement || '').trim().replace(/ /gi, ""), { lower: true });

    return (
        <div data-uk-alert className="uk-alert-danger uk-text-center">
            {warning}
            {replacement && (<span class="uk-text-emphasis"> - See replacement&nbsp;
                <Link
                    to={`/category/${categoryId
                        }/products/${slug}`}
                >
                    {replacement}
                </Link></span>
            )}
        </div>
    )
}

export default PhaseOutWarning;