import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import _ from "lodash"
import log from "loglevel"
import React from "react"
import IconCanada from "../assets/icons/canada.svg"
import IconUS from "../assets/icons/united-states.svg"
import ButtonList from "../components/button-list"
import FAQs from "../components/faqs"
import ImageGridWithZoom from "../components/image-grid-zoom"
import Layout from "../components/layout"
import Notes from "../components/notes"
import PhaseOutWarning from "../components/phase-out-warning"
import RefrigerantSeries from "../components/refrigerant-series"
import TitleWithCopyLink from "../components/title-copy-link"
import { numberWithCommas } from "../utils/formatting"

const OutdoorUnit = ({ data, pageContext }) => {
  if (!data) {
    return <>Error getting data</>
  }
  const product = data.product.nodes[0] || {}
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategoryId = pageContext.subcategoryId
  const faqs = data.faqs.nodes

  // log.info(data)
  const singleModule = _.uniq(
    product.models
      .filter(model => model.moduleType === "Single")
      .map(model => model.moduleCapacity)
  )
  const dualModule = _.uniq(
    product.models
      .filter(model => model.moduleType === "Dual")
      .map(model => model.moduleCapacity)
  )
  const tripleModule = _.uniq(
    product.models
      .filter(model => model.moduleType === "Triple")
      .map(model => model.moduleCapacity)
  )

  product.image = (
    data.images.edges?.map(edge => edge.node?.parent) || []
  ).filter(img => !img.name.toLowerCase().includes("landing"))?.[0]

  product.clearanceImages = data.clearanceImages.edges.map(
    edge => (edge.node || {}).parent
  )

  product.pipingImages = data.pipingImages.edges.map(
    edge => (edge.node || {}).parent
  )

  product.models.forEach(model => {
    if (model.submittalSheet) {
      model.submittalSheet.path = (
        (
          data.submittalSheet.edges.find(
            edge => edge.node.name === model.modelNumber
          ) || {}
        ).node || {}
      ).publicURL
    }
  })

  log.info(product, data, faqs, pageContext)

  const getAvailability = (powerSupply: string, capacities: any[]) => {
    return (
      capacities
        .map((capacity, j) => {
          const model =
            product.models.find(
              _model =>
                _model.powerSupply === powerSupply &&
                _model.capacity === capacity
            ) || {}
          const availability: any[] = []

          if (!!model.availableUS) {
            availability.push({
              name: "USA",
              src: <IconUS style={{ width: "24px" }} />,
            })
          }
          if (!!model.availableCA) {
            availability.push({
              name: "CAN",
              src: <IconCanada style={{ width: "24px" }} />,
            })
          }
          return availability
        })
        .reduce((arr, item) => arr.concat(item), [])
        .filter(
          (item, i, arr) =>
            arr.findIndex(_item => _item.name === item.name) === i
        )
        // .map((item, i)=> <div key={`icon_${i}`} style={{margin: '0 5px', display: 'inline-block'}}><img style={{width: '24px', display: 'block'}}  src={item.src} /><span>{item.name}</span></div>)
        .map((item, i) => (
          <div
            key={`icon_${i}`}
            style={{ margin: "0 5px", display: "inline-block" }}
            data-uk-tooltip={`title: ${item.name}; pos: right`}
          >
            {item.src}
          </div>
        ))
    )
  }

  // log.info(product.name.toLowerCase())
  //   log.info(query.allImageSharp.edges.filter(edge =>
  //     edge.node.parent.name.toLowerCase().includes("piping")))
  // log.info(query.allImageSharp.edges)

  return (
    <Layout title={product.name} navItems={pageContext.categories || []}>
      <div className="uk-section-xsmall">
        <div className="uk-container uk-container-large">
          <ul className="uk-breadcrumb">
            <li>
              <span>{category.name}</span>
            </li>
            <li>
              <Link
                to={`/category/${category._id}/sub/${selectedSubcategoryId}`}
              >
                {product.category}
              </Link>
            </li>
            <li>
              <span>{product.productSeries}</span>
            </li>
          </ul>
          <div className="uk-section-xsmall">
            <div className="uk-container uk-container-large uk-text-center">
              {/* <h2>{category.name}</h2> */}
              <ButtonList
                items={subcategories}
                parentId={category._id}
                selectedItemId={selectedSubcategoryId}
              ></ButtonList>
            </div>
          </div>
          <div className="product uk-section-xsmall">
            <h1 className="uk-text-center white-space-pre-line">
              {product.name}
            </h1>
            {(product.subcategory || "").trim().toLowerCase() ===
            "other daikin vrv condensing units" ? (
              <></>
            ) : (
              <h4 className="uk-text-center">{product.subcategory}</h4>
            )}
            <RefrigerantSeries value={product.refrigerantSeries} />
            <PhaseOutWarning
              categoryId={category._id}
              warning={product.phaseOutWarning}
              replacement={product.replacementProductSeries}
            />

            <div className="uk-child-width-expand@s uk-grid-small" data-uk-grid>
              <div className="uk-padding-small">
                <table
                  className="uk-table uk-table-striped  uk-table-small"
                  style={{
                    maxWidth:
                      dualModule.length > 0 || tripleModule.length > 0
                        ? ""
                        : "400px",
                  }}
                >
                  <thead className="thead-primary">
                    <tr>
                      <th>Single modules</th>
                      {dualModule.length > 0 && <th>Dual modules</th>}
                      {tripleModule.length > 0 && <th>Triple modules</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {new Array(
                      Math.max.apply(null, [
                        singleModule.length,
                        dualModule.length,
                        tripleModule.length,
                      ])
                    )
                      .fill(1)
                      .map((mod, i) => (
                        <tr key={`module_row_${i}`}>
                          <td className="uk-text-center">
                            {i < singleModule.length ? singleModule[i] : ""}
                          </td>
                          {dualModule.length > 0 && (
                            <td className="uk-text-center">
                              {i < dualModule.length ? dualModule[i] : ""}
                            </td>
                          )}
                          {tripleModule.length > 0 && (
                            <td className="uk-text-center">
                              {i < tripleModule.length ? tripleModule[i] : ""}
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="uk-padding-small">
                <ImageGridWithZoom
                  images={[product.image]}
                  title={""}
                  maxWidth={""}
                  maxHeight={"300px"}
                  section={false}
                ></ImageGridWithZoom>

                <div
                  className="uk-text-center pre-wrap"
                  style={{ padding: "10px" }}
                >
                  <h5 className="uk-text-bold">Dimensions</h5>
                  <p className="uk-margin-remove">Height: {product.height}</p>
                  <p className="uk-margin-remove">Width: {product.width}</p>
                  <p className="uk-margin-remove">Depth: {product.depth}</p>
                </div>
              </div>
            </div>

            <div className="uk-section-xsmall">
              <div className="uk-overflow-auto">
                <table className="uk-table uk-table-striped uk-text-center uk-table-small">
                  <thead className="thead-primary uk-text-center">
                    <tr>
                      <th>Model Number</th>
                      <th>Nominal Size</th>
                      <th>Nominal Cooling (BTU/hr)</th>
                      <th>Nominal Heating (BTU/hr)</th>
                      {product.models.some(model => model.estimatedHeating) ? (
                        <th>Estimated Heating 0°F & -13°F (BTU/hr)</th>
                      ) : undefined}
                      <th>
                        {product.models.some(model => model.coolingEfficiency)
                          ? "Cooling Efficiency (non-ducted) (IEER)"
                          : product.models.some(
                              model => model.coolingEfficiencySEER
                            )
                          ? "Cooling Efficiency (SEER)"
                          : "Cooling Efficiency (IEER)"}
                      </th>
                      {product.models.some(
                        model =>
                          model.heatingEfficiency ||
                          model.heatingEfficiencyHSPF ||
                          model.heatingEfficiencyEWT
                      ) ? (
                        <th>
                          {product.models.some(model => model.heatingEfficiency)
                            ? "Heating Efficiency (non-ducted) 47°F & 17°F (COP)"
                            : product.models.some(
                                model => model.heatingEfficiencyHSPF
                              )
                            ? "Heating Efficiency (HSPF)"
                            : "Heating Efficiency (COP)"}
                        </th>
                      ) : undefined}
                      <th>
                        {product.models.some(model => model.airflow)
                          ? "Airflow (CFM)"
                          : "Waterflow (GPM)"}
                      </th>
                      <th>Net Weight (lb)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.models
                      .filter(model => model.cooling)
                      .map((model, i) => (
                        <tr key={`main_table_tr_${i}`}>
                          <td>{model.modelNumber.slice(0, -4)}</td>
                          <td>{model.capacity}</td>
                          <td>{numberWithCommas(model.cooling)}</td>
                          <td>{numberWithCommas(model.heating)}</td>
                          {product.models.some(
                            model => model.estimatedHeating
                          ) ? (
                            <td>{numberWithCommas(model.estimatedHeating)}</td>
                          ) : undefined}
                          <td>
                            {numberWithCommas(
                              model.coolingEfficiency ||
                                model.coolingEfficiencySEER ||
                                model.coolingEfficiencyEWT
                            )}
                          </td>
                          {product.models.some(
                            model =>
                              model.heatingEfficiency ||
                              model.heatingEfficiencyHSPF ||
                              model.heatingEfficiencyEWT
                          ) ? (
                            <td>
                              {numberWithCommas(
                                model.heatingEfficiency ||
                                  model.heatingEfficiencyHSPF ||
                                  model.heatingEfficiencyEWT
                              )}
                            </td>
                          ) : undefined}
                          <td>
                            {numberWithCommas(model.airflow || model.waterflow)}
                          </td>
                          <td>{model.weight}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {(product.notes || []).length ? (
                <div className="uk-margin-top">
                  <h5>Notes</h5>
                  <div className="uk-text-meta">
                    <ul>
                      {product.notes.map((feature, i) => (
                        <li key={`note_${i}`}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <Notes title={"Features"} items={product.features} />

            <ImageGridWithZoom
              images={product.clearanceImages}
              title={"Installation Clearances"}
            ></ImageGridWithZoom>

            <ImageGridWithZoom
              images={product.pipingImages}
              title={"Piping Length"}
            ></ImageGridWithZoom>

            <div className="uk-section-xsmall">
              <TitleWithCopyLink title={"Electrical Information"} type="h3" />

              <div className="uk-overflow-auto">
                <table className="uk-table uk-table-striped  uk-table-small">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th
                        className="uk-text-center th-primary"
                        colSpan={
                          _.uniq(
                            product.models
                              .filter(model => model.MCAMOP)
                              .map(model => model.capacity)
                          ).length
                        }
                      >
                        MCA (A) / MOP (A)
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th className="text-dark">Availability</th>
                      <th className="text-dark">Voltage</th>
                      {_.uniq(
                        product.models
                          .filter(model => !!model.MCAMOP)
                          .map(model => model.capacity)
                      ).map(cap => (
                        <th className="text-dark" key={`th_${cap}`}>
                          <>{cap}</>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_.uniq(product.models.map(model => model.powerSupply)).map(
                      (powerSupply, i) => {
                        const caps = _.uniq(
                          product.models.map(model => model.capacity)
                        )
                        return (
                          <tr key={`mca_row_${i}`}>
                            <td>{getAvailability(powerSupply, caps)}</td>
                            <td>
                              <>{powerSupply}</>
                            </td>
                            {caps.map((capacity, j) => {
                              const mca = (
                                product.models.find(
                                  _model =>
                                    _model.powerSupply === powerSupply &&
                                    _model.capacity === capacity
                                ) || {}
                              ).MCAMOP
                              return mca ? (
                                <td key={`mca_cap_row_${j}`}>{mca}</td>
                              ) : undefined
                            })}
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="uk-section-xsmall">
              <TitleWithCopyLink title={"Submittal Data Sheets"} type="h3" />

              <div className="uk-overflow-auto">
                <table
                  className="uk-table uk-table-striped  uk-table-small uk-margin-auto"
                  style={{
                    maxWidth:
                      _.uniq(product.models.map(model => model.powerSupply))
                        .length > 1
                        ? ""
                        : "400px",
                  }}
                >
                  <thead className="thead-primary">
                    <tr>
                      <th></th>
                      {_.uniq(
                        product.models.map(model => model.powerSupply)
                      ).map((electrical, i) => (
                        <th key={`ps_${electrical}`}>{electrical}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_.uniq(product.models.map(model => model.capacity)).map(
                      (capacity, i) => {
                        const electricals = _.uniq(
                          product.models.map(model => model.powerSupply)
                        )
                        return (
                          <tr key={`submittalSheets_row_${i}`}>
                            <td className="uk-text-bold text-dark">
                              {capacity}
                            </td>
                            {electricals.map((electrical, j, arr) => {
                              const model = product.models.find(
                                _model =>
                                  _model.powerSupply === electrical &&
                                  _model.capacity === capacity &&
                                  _model.submittalSheet &&
                                  _model.submittalSheet.path
                              )
                              return (
                                <td key={`submittalSheets_col_${j}`}>
                                  {((model || {}).submittalSheet || {}).path ? (
                                    <OutboundLink
                                      id={`submittal-sheet-${
                                        i * arr.length + j
                                      }`}
                                      href={model.submittalSheet.path}
                                      target="_blank"
                                    >
                                      {model.modelNumber}
                                    </OutboundLink>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <FAQs items={faqs} productSeries={product.productSeries}></FAQs>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OutdoorUnitProductAndImage(
    $productId: String
    $imageId: String
    $categoryRegex: String
    $subcategoryRegex: String
    $clearanceRegex: String
    $pipingRegex: String
    $submittalSheetIds: [String]
  ) {
    images: allImageSharp(filter: { original: { src: { regex: $imageId } } }) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    clearanceImages: allImageSharp(
      filter: { original: { src: { regex: $clearanceRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    pipingImages: allImageSharp(
      filter: { original: { src: { regex: $pipingRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    submittalSheet: allFile(
      filter: { extension: { eq: "pdf" }, name: { in: $submittalSheetIds } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    faqs: allFaqsJson(
      filter: {
        categoryId: { regex: $categoryRegex }
        subcategoryId: { regex: $subcategoryRegex }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    product: allProductsJson(filter: { _id: { eq: $productId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        name
        productId
        productSeries
        refrigerantSeries
        replacementProductSeries
        phaseOutWarning
        features
        notes
        subcategory
        width
        models {
          capacity
          CFM
          MCA
          MCAMOP
          MOP
          dimensions
          cooling
          heating
          maxSound
          modelNumber
          moduleCapacity
          moduleType
          name
          operatingSound
          ports
          powerSupply
          productSeries
          availableUS
          availableCA
          estimatedHeating
          coolingEfficiency
          heatingEfficiency
          coolingEfficiencySEER
          heatingEfficiencyHSPF
          heatingEfficiencyEWT
          airflow
          waterflow
          weight
          energyStar
          submittalSheet {
            url
            fileName
            type
          }
        }
      }
    }
  }
`

export default OutdoorUnit
